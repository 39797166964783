<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Old Password" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Old Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password" label="New Password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                @input="passwordFunction"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ PasswordMessageAlert }}</small>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Retype New Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                @input="confirmpasswordFunction"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ confirmPasswordMessageAlert }}</small>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="onSubmit"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
            @click="resetform"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    ToastificationContent,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: "",
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      alertMessageResponse: "",
      confirmPasswordMessageAlert: "",
      PasswordMessageAlert: "",
    };
  },

  mounted() {
    //  console.log("localhost",localStorage.getItem("permission"))

    if (localStorage.getItem("Datauser")) {
      var user_name = JSON.parse(localStorage.getItem("Datauser"));
      if (user_name.user) {
        this.id = user_name.user._id;
      }
    }
    // console.log("localhost",permission.roleName );
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    passwordFunction() {
      // console.log("this.userdata.password.length");
      if (this.newPasswordValue.length >= 6) {
        this.PasswordMessageAlert = "";
      } else {
        this.PasswordMessageAlert = "Password must be atleast 6 characters";
      }
      if (this.newPasswordValue.length == 0) {
        this.PasswordMessageAlert = "The Password field is required";
      }
    },
    confirmpasswordFunction() {
      // console.log("this.userdata.password.length");
      if (this.RetypePassword.length >= 6) {
        this.confirmPasswordMessageAlert = "";
      } else {
        this.confirmPasswordMessageAlert =
          "Confirm Password must be atleast 6 characters";
      }
      if (this.RetypePassword.length == 0) {
        this.confirmPasswordMessageAlert =
          "The Confirm Password field is required";
      }
    },
    resetform() {
      this.passwordValueOld = "";
      this.newPasswordValue = "";
      this.RetypePassword = "";
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    onSubmit() {
      // console.log("");
      if (
        this.newPasswordValue.length >= 6 &&
        this.RetypePassword.length >= 6
      ) {
        if (this.newPasswordValue == this.RetypePassword) {
          // alert(0)
          let changepassword = {
            id: this.id,
            old_password: this.passwordValueOld,
            password: this.newPasswordValue,
          };
          axios
            .post(
              process.env.VUE_APP_API_URL + "/USER_CHANGE_PASSWORD",
              changepassword
            )
            .then((response) => {
              this.alertMessageResponse = response.data.message;
              if (response.data.message == "Password Update") {
                this.update("success");
              } else {
                this.update("danger");
              }
              // console.log(response.data.message);
            });
        } else {
          (this.alertMessageResponse = "Please check your confirm password"),
            this.update("danger");
        }
      } else {
        this.alertMessageResponse = "Password must be atleast 6 characters"
        this.update("danger");
      }
    },
    update(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          // icon: "BellIcon",
          text: this.alertMessageResponse,
          variant,
        },
      });
    },
    reject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          // icon: "BellIcon",
          text: this.alertMessageResponse,
          variant,
        },
      });
    },
  },
};
</script>
