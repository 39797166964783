<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="UserData.avatar" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.click()"
        >
          <input
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">Upload</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <!-- upload button -->

        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetImage"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input
              v-model="UserData.name"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input
              v-model="UserData.fullName"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="UserData.email"
              name="email"
              @input="getUsers"
              placeholder="Email"
            />
          </b-form-group>
          <small class="text-danger">{{ wrongEmailAlert }}</small>
        </b-col>

        <!-- alert -->
        <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="submit"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!-- form -->
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import S3 from "aws-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,

    ToastificationContent,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   generalData: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      wrongEmailAlert: "",
      UserData: {
        id: "",

        name: "",
        avatar: "",
        email: "",
      },
      avatar: "",
      name: "",
      email: "",
      alertMessageResponse: "",
      alluser: "",
      // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      // profileFile: null,
    };
  },
  mounted() {
    //  console.log("localhost",localStorage.getItem("permission"))

    if (localStorage.getItem("Datauser")) {
      var user_name = JSON.parse(localStorage.getItem("Datauser"));
      if (user_name.user.avatar) {
        this.avatar = user_name.user.avatar;
        this.UserData.avatar = user_name.user.avatar;
      } else {
        this.avatar =
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg";

        this.UserData.avatar =
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg";
      }
      if (user_name.user) {
        this.UserData.id = user_name.user._id;
      }
      if (user_name.user) {
        this.UserData.email = user_name.user.email;
        this.email = user_name.user.email;
      }
      if (user_name.user) {
        // console.log("nameaaaaaaa", user_name.name);
        this.UserData.name = user_name.user.name;
        this.name = user_name.user.name;
      }
    }
    // console.log("localhost",permission.roleName );
  },
  created() {
    this.gettabledata();
  },
  methods: {
    getUsers() {
      this.wrongEmailAlert = "The Email field must be a valid email";
      if (this.ValidateEmail(this.UserData.email)) {
        let arr = [];
        for (let index = 0; index < this.alluser.length; index++) {
          if (this.UserData.email == this.alluser[index]) {
            // console.log("not valid");
            arr.push("not valid");
            return this.validated();
            // console.log();
          } else {
            arr.push("valid");

            // console.log("valid");
          }
        }
        return this.notvalidated();
      }
    },
    validated() {
      // console.log("valiate");
      this.wrongEmailAlert = "Email has already been taken!";
    },
    notvalidated() {
      this.wrongEmailAlert = "";
    },
    ValidateEmail(mail) {
      // console.log("mail",mail)
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      // alert("You have entered an invalid email address!");
      return false;
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/GET_EMAIL_VALIDATOR", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.alluser = get_response.data;
          //  console.log(" this.alluser", this.alluser);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    resetImage() {
      this.UserData.avatar = this.avatar;
    },
    submit() {
      // console.log("new user data", this.UserData);
      if (this.ValidateEmail(this.UserData.email)) {
        axios
          .post(
            process.env.VUE_APP_API_URL + "/USER_ACCOUNT_SETTINGS",
            this.UserData
          )
          .then((response) => {
            // console.log("newDS=ATA",response.data.newDATA);
            if (
              response.data.message == "Duplicate email please try another one."
            ) {
              this.alertMessageResponse = response.data.message;
              this.reject("danger");
            } else {
              this.UserData.avatar = response.data.newDATA.avatar;
              this.UserData.name = response.data.newDATA.name;
              this.UserData.email = response.data.newDATA.email;
              this.avatar = response.data.newDATA.avatar;
              this.name = response.data.newDATA.name;
              this.email = response.data.newDATA.email;
              var user_name = JSON.parse(localStorage.getItem("Datauser"));
              // console.log("user_name",user_name)
              user_name.user = response.data.newDATA;
              // console.log("user_name",user_name)
              var new_daata = JSON.stringify(user_name);

              localStorage.setItem("Datauser", new_daata);
              this.alertMessageResponse = response.data.message;
              this.update("success");
            }
            // this.ClassData.class_img = res/ponse.data;
          });
      } else {
        this.showToastemail("danger");
      }
    },
    showToastemail(variant) {
      this.$toast({
        component: ToastificationContent,

        props: {
          // background
          title: "Notification",
          icon: "BellIcon",
          text: "Please enter a valid email",
          variant,
          color: "danger",
        },
      });
    },
    update(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          // icon: "BellIcon",
          text: this.alertMessageResponse,
          variant,
        },
      });
      this.reloadwindow();
    },
    reloadwindow() {
      // alert(0);
      location.reload();
    },
    reject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          // icon: "BellIcon",
          text: this.alertMessageResponse,
          variant,
        },
      });
    },

    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.UserData.avatar = data.location))
          .catch((err) => console.error(err));
      }
    },
    resetForm() {
      (this.UserData.name = this.name),
        (this.UserData.email = this.email),
        (this.UserData.avatar = this.avatar);
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
};
</script>
